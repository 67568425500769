#footer{
    //position: fixed;
    //bottom: 0;
    //width: 100%;
    


    height: 25px;
    color: white;
    background-color: black;
    text-align: center;


    div{
        margin: 0 20px 0 20px;
    }
    .clickable{
        &:hover{
            cursor: pointer;
        }
    }

}