@import '../styles/_variables.sass';
#resultat-win-page{
    padding-top: 20px;
    padding-bottom: 0;
    background-image: url(../../public/img/BG_Resultat.jpg);
    background-size: cover;
    background-position: bottom;
    overflow: auto;

    @media screen and (min-width: $md) {
        height: calc(100vh - 130px);
     }

    @media screen and (max-width: $md) {
        padding-top: 0;
        padding-bottom: 0;
        background-image: none;
     }
     
     #panel-header{
        width: 100%;
        @media screen and (max-width: $md) {
            height: 300px;
            padding-top: 20px;
            padding-bottom: 50px;
            background-image: url(../../public/img/BG_Resultat.jpg);
            background-size: cover;
            background-position: bottom;
         }
     }

    #container{
        max-width: 800px;
        @media screen and (max-width: $md) {
            margin: 0;
            padding: 0;
            max-width: 100%;
         }

         #footer-win{
            color: initial;
            padding: 20px 3rem ;
            margin: 0;
            background-color: #E7D7CA;
            

            @media screen and (max-width: $md) {
                text-align: center;
                padding: 15px 20px ;
            }

         }

         .link{
            color:#D7BCA7!important;
         }
    }
    
    #panel-result{

        padding: 0 20px;
        @media screen and (min-width: $md) {
            padding: 0 60px;
        }



        #profil-type{
            color: #B7927F;
            text-transform: uppercase;
        }
    
    
        .result-item{
            min-width: 300px;
            height: 360px;
            background-color: #F2EDE7;
    
            .header-img{
    
                &.original{
                    height: 100px;
                    width: 120px;
                }
    
                &.vertuo{
                    height: 120px;
                    width: 160px;
                }
                
                background-size: cover;
                background-position: center;
                margin: auto;
            }
        }
    
    
        #win-panel{
            background-color: #B5927E;
            color: white;
            .win-image{
                height: 180px;
                width: 100%;
                background-image: url(../../public/img/visuel_resultat.jpg);
                background-size: cover;
                background-position: center;

                @media screen and (min-width: $md) {
                    height: 250px;
                }
            }

            .win-text{
                @media screen and (min-width: $md) {
                    font-size: 20px;
                }
                
            }

            .win-btn{
                background-color:  #D7BCA7;
            }
        }

        .nespresso-link{
            width: 220px;
            background-color:  #fff;
            color:#B7927F;;
            border: solid 2px #B7927F;
        }
    }
}