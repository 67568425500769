@import '../styles/_variables.sass';

#question-page{


    padding-top: 20px;
    padding-bottom: 50px;
    //background-image: url(../../public/img/BG_Question_3.jpg);
    background-image: url(../../public/img/BG_Question_3_v2.jpg);
    background-size: cover;
    background-position: top;
    overflow: auto;

     @media screen and (max-width: 768px) {
        padding-top: 0;
        padding-bottom: 0;
        background-image: none;
     }

     #panel-header{
        width: 100%;
        @media screen and (max-width: 768px) {
            padding-top: 20px;
            padding-bottom: 50px;
            background-image: url(../../public/img/BG_Question_3_v2.jpg);
            background-size: cover;
            background-position: top;
         }

         #title{
            @media screen and (max-width: 768px) {
                color: #212529!important;
                background-color: #ffffffe3;
                width: 70%;
                margin: auto;
                padding: 30px;
             }
         }
     }

    .questions{
        
        .item{
            //max-width: 250px;
            background-color: white;
            margin: 0 10px 0 10px;
            margin-top: 20px;

            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            @media screen and (max-width: 768px) {
                margin:20px 0 0 0;
                display: flex;
                .content{
                    height: auto;
                    width: 100%;
                }
            }


            &:hover{
                background-color: #B7927F;
                color:white;
                cursor: pointer;
            }

            &.selected{
                background-color: #B7927F;
                color:white;
            }
        }

        .header-img{
            height: 150px;
            width: 150px;
            background-size: cover;
            background-position: center;

            @media screen and (min-width: $md) {
                min-height: 150px;
                height: 174px;
                width: 174px;
                flex-basis: 1;
            }
            @media screen and (min-width: $lg) {
                height: 234px;
                width: 234px;
            }

            @media screen and (min-width: $xl) {
                height: 279px;
                width: 279px;
            }

            @media screen and (min-width: $xxl) {
                height: 324px;
                width: 324px;
            }

        }

        .content{
            height: 200px;
            padding: 10px;

            #response-title
            {
                font-size: 18px;
                @media screen and (max-width: 768px) {
                    font-size: 18px;
                }
            }

            #response-subtitle{
                line-height: 1.2;
                @media screen and (max-width: 768px) {

                    font-size: 14px;
                    
                }
            }
        }

    }
    
    #btn-validate{
        .back{
            width: 50px!important;
            margin-right: 10px;
        }
        button{
            width: 300px;
        }
    }

    #progress{
        .step{
            text-align: center;
            height: 30px;
            width: 30px;
            color:#fff;
            border: 2px #fff solid;
            border-radius: 25px;
            margin: 0 20px 0 20px;
            padding-top: 2px;
            @media screen and (max-width: 768px) {
                color:#B7927F;
                border: 2px #B7927F solid;
            }


            &.selected{
                color: #B7927F;
                background-color: #fff;

                @media screen and (max-width: 768px) {
                    color:#fff;
                    background-color: #B7927F;
                }
            }
        }

        .separator{
            height: 3px;
            width: 35px;
            background-color: #fff;

            @media screen and (max-width: 768px) {
                background-color: #B7927F;
            }
        }
    }

    #show-more{
        background-color: #E7D6CA;
        width: 78%;
       
        margin: auto;
        padding: 10px;

        .text{
            @media screen and (max-width: 768px) {
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }

        @media screen and (max-width: 768px) {
            text-align: center;
            width:100%;
            max-width: 100%;

        }


        .popin{
            width: 100px!important;
        }
    }


}