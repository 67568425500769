#header{
    text-align: center;
    margin-bottom: 10px;
    #logo{
        height: 35px;
    }


    @media screen and (max-width: 768px) {
        #logo{
            height: 20px;
        }

        h1{
            font-size: 30px;
        }
    }
}