.popin{
    .modal-content{
        border-radius: 0;
    }
    
    .modal-header{
        border-bottom: none;
    }

    .modal-footer{
        border-top: none;
    }

}