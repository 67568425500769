@import '../styles/_variables.sass';
#resultat-page{
    padding-top: 20px;
    padding-bottom: 100px;
    background-image: url(../../public/img/BG_Resultat.jpg);
    background-size: cover;
    background-position: bottom;
    min-height: 100%;
    overflow: auto;
    @media screen and (max-width: $md) {
        padding-top: 0;
        padding-bottom: 0;
        background-image: none;
     }
     
     #panel-header{
        width: 100%;
        @media screen and (max-width: $md) {
            height: 300px;
            padding-top: 20px;
            padding-bottom: 50px;
            background-image: url(../../public/img/BG_Resultat.jpg);
            background-size: cover;
            background-position: bottom;
         }
     }

    #container{
        @media screen and (max-width: $md) {
            margin: 0;
            padding: 0;
            max-width: 100%;
         }
    }
    

    #title{
        background-color: #E7D6CA;
        padding: 20px 0 20px 0;
        
        @media screen and (max-width: $md) {
           
         }
    }

    #panel-result{

        padding: 0 20px;
        @media screen and (min-width: $md) {
            padding: 0 60px;
        }



        #profil-type{
            color: #B7927F;
            text-transform: uppercase;
        }
    
    
        .result-item{
            min-width: 300px;
            height: 320px;
            background-color: #F2EDE7;
            padding: 50px 10px 0 10px;

            &.original{
                padding: 50px 10px 0 10px;
            }

            &.vertuo{
                padding: 20px 10px 0 10px;
            }


            .header-img{
    
                cursor: pointer;
                &.original{
                    height: 100px;
                    width: 120px;
                }
    
                &.vertuo{
                    height: 120px;
                    width: 160px;
                }
                
                background-size: cover;
                background-position: center;
                margin: auto;
            }
        }
    
    
        #win-panel{
            background-color: #B5927E;
            color: white;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

            @media screen and (max-width: $md) {
                padding: 20px;
            }


            .win-image{
                height: 180px;
                width: 100%;
                background-image: url(../../public/img/visuel_resultat.jpg);
                background-size: cover;
                background-position: center;


                @media screen and (min-width: $md) {
                    height: 250px;
                }

            }

            .win-text{
                @media screen and (min-width: $md) {
                    font-size: 20px;
                }
                
            }

            .win-btn{
                background-color:  black;
                &:disabled{
                    color: white!important;
                }
                
            }
        }

        .nespresso-link{
            width: 220px;
            background-color:  #fff;
            color:#B7927F;;
            border: solid 2px #B7927F;
        }
    }
}