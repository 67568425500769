// Cold colors
$aqua: #7fdbff
$blue: #0074d9
$navy: #001f3f
$teal: #39cccc
$green: #2ecc40
$olive: #3d9970
$lime: #01ff70

// Warm colors
$yellow: #ffdc00
$orange: #ff851b
$red: #ff4136
$fuchsia: #f012be
$purple: #b10dc9
$maroon: #85144b

// Grayscale
$white: #ffffff
$silver: #dddddd
$gray: #aaaaaa
$black: #111111

$main-background-color: $white
$main-text-color: $black
$divider-color: rgba($black, .14)

$main-text-size: 14px
$main-border-radius: 6px

$small-space: 16px
$medium-space: 32px
$large-space: 64px

$phone-size: 600px
$tablet-size: 992px
$desktop-size: 1200px


$xs: 0
$sm: 576px
$md: 768px
$lg: 992px
$xl: 1200px
$xxl: 1400px