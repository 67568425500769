
.btn-custom{
    text-transform: uppercase;
    border: none;
    color: #fff;
    padding: 10px;
    background-color: #B7927F;
    font-weight: bold;
    &.sm{
        font-size: 12px;
        padding: 0px 10px 0px 10px;
    }
    &:disabled{
        background-color:  #fff;
        color:#B7927F;;
        border: solid 2px #B7927F;
    }
}