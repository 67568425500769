@font-face {
    font-family: 'NespressoLucas-Bold';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/Bold/NespressoLucas-Bold.eot') format('eot'),
        url('./fonts/Bold/NespressoLucas-Bold.ttf') format('truetype'),
        url('./fonts/Bold/NespressoLucas-Bold.svg') format('svg'),
        url('./fonts/Bold/NespressoLucas-Bold.woff') format('woff');
}

@font-face {
    font-family: 'NespressoLucas-BoldItalic';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/BoldItalic/NespressoLucas-BoldItalic.eot') format('eot'),
         url('./fonts/BoldItalic/NespressoLucas-BoldItalic.ttf') format('truetype'),
         url('./fonts/BoldItalic/NespressoLucas-BoldItalic.svg') format('svg'),
         url('./fonts/BoldItalic/NespressoLucas-BoldItalic.woff') format('woff');
}

@font-face {
    font-family: 'NespressoLucas-Italic';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/Italic/NespressoLucas-Italic.eot') format('eot'),
         url('./fonts/Italic/NespressoLucas-Italic.ttf') format('truetype'),
         url('./fonts/Italic/NespressoLucas-Italic.svg') format('svg'),
         url('./fonts/Italic/NespressoLucas-Italic.woff') format('woff');
}

@font-face {
    font-family: 'NespressoLucas-Light';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/Light/NespressoLucas-Light.eot') format('eot'),
         url('./fonts/Light/NespressoLucas-Light.ttf') format('truetype'),
         url('./fonts/Light/NespressoLucas-Light.svg') format('svg'),
         url('./fonts/Light/NespressoLucas-Light.woff') format('woff');
}

@font-face {
    font-family: 'NespressoLucas-Regular';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/Regular/NespressoLucas-Regular.eot') format('eot'),
         url('./fonts/Regular/NespressoLucas-Regular.ttf') format('truetype'),
         url('./fonts/Regular/NespressoLucas-Regular.svg') format('svg'),
         url('./fonts/Regular/NespressoLucas-Regular.woff') format('woff');
}

#root{
    height: calc(100vh - 25px);
}

body{
    font-family: 'NespressoLucas-Regular'!important;
    overflow: hidden;
}

.font-regular{
    font-family: 'NespressoLucas-Regular'!important;
}

.font-bold{
    font-family: 'NespressoLucas-Bold'!important;
}

.fs-18px{
    font-size: 18px!important;
}


.fs-14px{
    font-size: 14px!important;
}

.fs-11px{
    font-size: 11px!important;
}

a:link    { color: inherit!important}
a:visited { color: inherit!important}
a:hover   { color: inherit!important}
a:active  { color: inherit!important}

b{
    font-family: 'NespressoLucas-Bold'!important;
}