@import '../styles/_variables.sass';

#inscription-page{
    padding-top: 20px;
    padding-bottom: 50px;

    background-image: url(../../public/img/BG_LP_2.jpg);
    background-size: cover;
    background-position: top;
    //background-position-y:calc(100% - -140px);
    //background-position-x: center;

    min-height:100%;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media screen and (min-width: 1715px) {
        //background-position-y: calc(100% - -140px);
        background-position-y: calc(100% - -114px);
     }

     @media screen and (max-width: $md) {
        padding-top: 0;
        padding-bottom: 0;
        background-image: none;
     }

    
     #panel-header{
        width: 100%;
        @media screen and (max-width: 768px) {
            padding-top: 20px;
            padding-bottom: 50px;
            background-image: url(../../public/img/BG_LP_2.jpg);
            background-size: cover;
            //background-position-y: calc(100% - -40px);
            //background-position-y: calc(100% - -95px);
         }

         #title{
            display: none;
            @media screen and (max-width: 768px) {
                display: block;
                color: #212529!important;
                background-color: #ffffffe3;
                width: 70%;
                margin: auto;
                padding: 20px;
             }
         }
     }


    #panel{
        max-width: 1000px;
        background-color: #f3f3f3ec;
       
        #panel-header-desktop{
            @media screen and (max-width: 768px) {
                display: none;
             }
        }


        #description-lot {
            margin-top: 35px;
            @media screen and (max-width: 768px) {
                margin-top: 5px;
            }

            .prices{
                position: relative;
                padding: 30px 0px 30px 0px;
                margin: 10px;
                font-weight: bold;
                color: white;
                line-height: 1;
                height: 220px;
                
    
    
                @media screen and (max-width: 768px) {
                    height: 100px;
                    padding: 10px 5px 5px 5px;
                }
    
    
    
                &.lot1{
                    background-color: #B7927F;
                    .hover-section{
                        color: #B7927F;
                    }
                }
                &.lot2{
                    background-color: #D8BCA7;
                    .hover-section{
                        color: #D8BCA7;
                    }
                }
                &.lot3{
    
                    background-color: #E8D6C9;
                    padding-top: 60px;
                    @media screen and (max-width: 768px) {
                        padding-top: 20px;
                    }
                    .hover-section{
                        color: #E8D6C9;
                    }
                }
    
    
                .hover-section{
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    background-color: #fff;
    
                    width: 25px;
                    height: 25px;
    
                    text-align: center;
                    vertical-align: middle;
                    font-size: 12px;
                    padding-top: 5px;
    
                    &:hover{
                        cursor: pointer;
                    }
                }
            }
    
            .or{
                flex-grow: 0;
                padding-left: 0;
                padding-right: 0;
                text-transform: uppercase;
                color:#D8BCA7;
            }
        }


        


        .presentation{
            padding: 25px;
        }

        .form{
            padding: 25px;
            background-color: #fdf9f8d5;


            input[type=text]{
                border-radius: 0;
                &::placeholder{
                    color:#212529
                }
            }

            .form-check-label{
                color: #979797;
                line-height: 1;
            }

            a:link { color: #979797}
            a:visited { color: #979797}
            a:hover { color: #979797}
            a:active { color: #979797}

        }


    }

}

#footer-legal-information{
    background-color: #D8BCA7;
    color: white;
    line-height: 1;
    padding: 25px 50px 25px 50px ;
}

